import { Functions } from "../../sections/Functions/functions"
import { Footer } from "../../sections/Footer/footer"
import { NavbarItem } from "../../components/NavbarItem/navbarItem"

export const Home = () => {
  return (
    <>
      <div id="top"></div>
      <div className="row sticky-top">
        <NavbarItem></NavbarItem>
      </div>
      
      <Functions></Functions>
      <div className="row">
        <Footer></Footer>
      </div>
    </>
  )
}