import './footer.css';

export const Footer = () => {

  return (
    <div id="footer" className="footer pn-bg-gray">
      <div id='contacto' className='container'>

        <div className='row'>
          <div className='col-12'>

            <div className='row top-wrapper justify-content-center'>
              <div className='img-wrapper' style={{cursor: 'pointer'}}>
                <a href="#top" className="td-text-blue td-font-16">
                  <img src='assets/footer-top.png' alt="Flecha superior"/>
                </a>

              </div>
              <div className=''>
                <p>Inicio</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-xl-10 offset-xl-1">
                <div className="row logo-wrapper">
                  <div className='col-4 text-start'>
                    <img className='img-fluid' src="assets/logo-footer.png" alt="Logo de Pinlet en blanco"/>
                  </div>
                </div>

                <div className="row links-wrapper">
                  <div className="col-6 col-md-4 col-lg-3 text-start">
                    <p className="link-header">Acerca de</p>
                    <p className="link">Nosotros</p>
                    <p className="link">Políticas de privacidad</p>
                    <p className="link">Términos y condiciones</p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 text-start">
                    <p className="link-header">Contacto</p>
                    <p className="link">Email: info@pinlet.net</p>
                    <p className="link">Teléfono: +593939858060</p>
                    <p className="link">Dirección: Ecuador, Guayaquil.</p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 text-start">
                    <p className="link-header">Servicio al cliente</p>
                  </div>

                  <div className="col-12 col-md-8 col-lg-3 text-start">
                    <div className="row">
                      <p className="link-header">Siguenos</p>
                    </div>
                    <div className="row">
                      <img className='network-logo' src="assets/facebook-logo.png" alt="Logo de Facebook"></img>
                      <img className='network-logo' src="assets/instagram-logo.png" alt="Logo de Twitter"></img>
                      <img className='network-logo' src="assets/linkedin-logo.png" alt="Logo de LinkedIn"></img>
                    </div>
                    <div className="row" style={{marginTop: '.5rem'}}>
                      <p className="link-header">Descarga la App</p>
                    </div>
                    <div className="row">
                      <div className='col-6'>
                        <img className='img-fluid' src="assets/appstore-white.png" alt="Logo de AppStore"></img>
                      </div>
                      <div className='col-6'>
                        <img className='img-fluid' src="assets/playstore-white.png" alt="Logo de PlayStore"></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}